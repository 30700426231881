export const rooms = (data) => ({
  type: "ROOMS",
  payload: data,
});

export const fetchRooms = (profileId) => {
  return async (dispatch) => {
    let headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
    };

    const authHeader =
      getCookie(process.env.REACT_APP_AUTH_COMPANY_COOKIE) ||
      getCookie(process.env.REACT_APP_AUTH_ACCOUNTANT_COOKIE);

    if (authHeader) {
      localStorage.setItem("authToken", authHeader);
      headers["Authorization"] = "Bearer " + authHeader;
    }

    return await fetch(
      `${process.env.REACT_APP_API_HOST}/meet-rooms?relations[]=meetRoomParticipants`,
      {
        method: "GET",
        headers,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode === 401) {
          window.location.replace(process.env.REACT_APP_AUTH_UI_HOST);
        } else if (data.message) {
          console.log(data);
        } else {
          return dispatch(rooms(data));
        }
      })
      .catch((error) => {
        console.log("Error in fetching rooms", error);
      });
  };
};
