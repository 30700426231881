import React, { useState, useEffect } from "react";

import Typography from "@material-ui/core/Typography";

import { config } from "../config";

import { useSelector } from "react-redux";

import { Link } from "@material-ui/core";

const Logo = () => {
  const [path, setPath] = useState("");

  const { me } = useSelector((s) => s.me);

  useEffect(() => {
    me?.userType === "ACCOUNTANT" &&
      setPath(process.env.REACT_APP_ACCOUNTANT_UI_HOST);
    me?.userType === "COMPANY" &&
      setPath(process.env.REACT_APP_COMPANY_UI_HOST);
  }, [me]);

  return (
    <>
      {config.logo ? (
        !!path ? (
          <Link href={path} target="_blank" rel="noopener" underline="none">
            <img alt="Logo" src={config.logo} />
          </Link>
        ) : (
          <img alt="Logo" src={config.logo} />
        )
      ) : (
        <Typography variant="h5"> {config.title} </Typography>
      )}
    </>
  );
};

export default Logo;
