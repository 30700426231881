export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

export function getCurrentBusinessProfileId() {
  const data = getCookie(
    process.env.REACT_APP_ACCOUNTANT_STATE_COOKIE ||
      process.env.REACT_APP_COMPANY_STATE_COOKIE
  );
  if (data) {
    try {
      const jsonData = JSON.parse(decodeURIComponent(data));
      if ("currentBusinessProfileId" in jsonData) {
        return jsonData.currentBusinessProfileId;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error parsing JSON data:", error);
      return null;
    }
  }
  return null; // Cookie not found or invalid format
}
