const initialState = {};

const rooms = (state = initialState, action) => {
  switch (action.type) {
    case "ROOMS": {
      const data = action.payload;
      return {
        ...state,
        rooms: data,
      };
    }
    default:
      return state;
  }
};

export default rooms;
